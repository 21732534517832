import Vue from "vue";

export const showNotification = (message, type = 'success') => {
  const isMobileWidth = window.innerWidth <= 550;
  Vue.$toast.open({
    type,
    message,
    position: isMobileWidth ? 'top' : 'bottom-right'
  });
}

export const getRandomTags = (tags) => {
  if (!Array.isArray(tags)) {
    return [];
  }
  const set = [...new Set(tags)];
  if (set.length < 5) {
    return [];
  }
  let array = [];
  const getRandomItem = () => tags[Math.floor(Math.random() * tags.length)];
  while (array.length < 5) {
    const item = getRandomItem();
    if (!array.includes(item)) {
      array.push(item);
    }
  }
  return array;
}