<template>
  <button :disabled="disabled" @click="$emit('on-click')">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['disabled'],
}
</script>

<style lang="scss" scoped>
  button{
    padding: 15px 0;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 900;
    font-size: 16px;
    background: $black;
    color: $white;
    width: 210px;
    display: block;

    @media (max-width: $mobile-screen) {
      font-size: 14px;
    }
  }
</style>
